// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // Opcional: para um tema escuro
    primary: {
      main: '#1A73E8', // Azul padrão do Google
    },
    secondary: {
      main: '#6E6E6E', // Cinza médio
    },
    background: {
      default: '#F5F5F5', // Cinza claro para o fundo
      paper: '#FFFFFF',   // Branco para cartões
    },
    text: {
      primary: '#212121', // Preto suave para texto
      secondary: '#757575', // Cinza para texto secundário
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

export default theme;
