// src/components/ClientesAtivos.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

function ClientesAtivos() {
  const [clientesAtivos, setClientesAtivos] = useState(0);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/clientes_ativos`)
      .then(response => {
        setClientesAtivos(response.data.total);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);

  return (
    <Card sx={{ backgroundColor: 'background.paper' }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <PeopleIcon color="primary" sx={{ fontSize: 40, mr: 2 }} />
          <Box>
            <Typography color="textSecondary" gutterBottom>
              Clientes Ativos
            </Typography>
            <Typography variant="h5">{clientesAtivos}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ClientesAtivos;
