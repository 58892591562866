// src/components/ClientesChurn.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Box } from '@mui/material';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

function ClientesChurn() {
  const [clientesChurn, setClientesChurn] = useState(0);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/clientes_churn`)
      .then(response => {
        setClientesChurn(response.data.total);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);

  return (
    <Card sx={{ backgroundColor: 'background.paper' }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <TrendingDownIcon color="error" sx={{ fontSize: 40, mr: 2 }} />
          <Box>
            <Typography color="textSecondary" gutterBottom>
              Clientes Churn
            </Typography>
            <Typography variant="h5">{clientesChurn}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ClientesChurn;
