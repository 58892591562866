// src/components/TaxaNPS.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import GaugeChart from 'react-gauge-chart';

function TaxaNPS() {
  const [nps, setNPS] = useState(0);
  const [promoters, setPromoters] = useState(0);
  const [passives, setPassives] = useState(0);
  const [detractors, setDetractors] = useState(0);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/taxa_nps`)
      .then(response => {
        const npsValue = response.data.nps;
        setNPS(npsValue);
        setPromoters(response.data.promoters);
        setPassives(response.data.passives);
        setDetractors(response.data.detractors);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);

  return (
    <Card sx={{ backgroundColor: 'background.paper', height: '100%', position: 'relative' }}>
      <CardContent>
        {/* Placa de "Em Construção" cobrindo todo o dashboard */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 187, 0, 0.9)',
            color: '#000000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
            flexDirection: 'column',
            boxShadow: 3,
          }}
        >
          <Typography variant="h3" align="center" fontWeight="bold">
            Em Construção
          </Typography>
          <Typography variant="h5" align="center" sx={{ mt: 2 }}>
            Estamos trabalhando para trazer essa funcionalidade em breve!
          </Typography>
        </Box>

        <Typography color="textSecondary" gutterBottom variant="h6">
          Taxa de NPS
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          {/* Gráfico de Gauge */}
          <GaugeChart
            id="nps-gauge"
            nrOfLevels={20}
            arcsLength={[0.4, 0.3, 0.3]}
            colors={['#FF4E42', '#FFBB00', '#00C853']}
            percent={(nps + 100) / 200}
            arcPadding={0.02}
            needleColor="#757575"
            textColor=""
            hideText={true}
            style={{ width: '100%', maxWidth: '300px', opacity: 0.4 }}
          />
          <Typography variant="h4" align="center" sx={{ mt: 2, opacity: 0.4 }}>
            {nps}%
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={4}>
            <Box
              sx={{
                backgroundColor: '#00C853',
                p: 2,
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Typography variant="subtitle1" align="center" color="#FFFFFF">
                Bom
              </Typography>
              <Typography variant="h5" align="center" color="#FFFFFF">
                {promoters}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                backgroundColor: '#FFBB00',
                p: 2,
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Typography variant="subtitle1" align="center" color="#000000">
                Satisfeito
              </Typography>
              <Typography variant="h5" align="center" color="#000000">
                {passives}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                backgroundColor: '#FF4E42',
                p: 2,
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Typography variant="subtitle1" align="center" color="#FFFFFF">
                Ruim
              </Typography>
              <Typography variant="h5" align="center" color="#FFFFFF">
                {detractors}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TaxaNPS;
