// src/components/ProdutosVendidos.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

function ProdutosVendidos() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/produtos_vendidos`)
      .then(response => {
        setData(response.data.produtos);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);

  return (
    <Card sx={{ backgroundColor: 'background.paper', height: '100%' }}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Produtos Vendidos
        </Typography>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={data}>
            <XAxis dataKey="produto" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="quantidade" fill="#1A73E8" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}

export default ProdutosVendidos;
