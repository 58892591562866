// src/App.js
import React from 'react';
import { Container, Grid, Typography, AppBar, Toolbar, Box } from '@mui/material';
import ClientesAtivos from './components/ClientesAtivos';
import ClientesAlerta from './components/ClientesAlerta';
import ClientesChurn from './components/ClientesChurn';
import TaxaNPS from './components/TaxaNPS';
import ProdutosVendidos from './components/ProdutosVendidos';
import ClientesPorGestor from './components/ClientesPorGestor';
import ProjetosAtivos from './components/ProjetosAtivos';

function App() {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">AR Digital Labs Dashboard</Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', py: 4 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {/* Linha 1 */}
            <Grid item xs={12} sm={6} md={3}>
              <ClientesAtivos />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ClientesAlerta />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ClientesChurn />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ProjetosAtivos />
            </Grid>
            {/* Linha 2 */}
            <Grid item xs={12} md={6}>
              <TaxaNPS />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProdutosVendidos />
            </Grid>
            {/* Linha 3 */}
            <Grid item xs={12}>
              <ClientesPorGestor />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default App;
