// src/components/ClientesPorGestor.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';

function ClientesPorGestor() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/clientes_por_gestor`)
      .then(response => {
        setData(response.data.gestores);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);

  const COLORS = ['#1A73E8', '#6E6E6E', '#FFBB28', '#FF7043', '#66BB6A'];

  return (
    <Card sx={{ backgroundColor: 'background.paper' }}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Clientes por Gestor
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              dataKey="quantidade"
              nameKey="gestor"
              cx="50%"
              cy="50%"
              outerRadius={100}
              label
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}

export default ClientesPorGestor;
