// src/components/ClientesAlerta.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

function ClientesAlerta() {
  const [clientesAlerta, setClientesAlerta] = useState(0);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/clientes_alerta`)
      .then(response => {
        setClientesAlerta(response.data.total);
      })
      .catch(error => {
        console.error('Erro:', error);
      });
  }, []);

  return (
    <Card sx={{ backgroundColor: 'background.paper' }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <WarningIcon color="secondary" sx={{ fontSize: 40, mr: 2 }} />
          <Box>
            <Typography color="textSecondary" gutterBottom>
              Clientes em Alerta
            </Typography>
            <Typography variant="h5">{clientesAlerta}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ClientesAlerta;
